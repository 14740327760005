import { currentDefaultNotificationID } from '$lib/localNotifications/consts';
import { _doPermissionsCheck } from '$lib/localNotifications/doPermissionCheck';
import { createLocalNotificationQueryKey } from '$lib/localNotifications/tanstack/createLocalNotificationQueryKey';
import * as m from '$lib/paraglide/messages.js';
import queryClient from '$lib/utilities/tanstack/queryClient';
import { LocalNotifications } from '@capacitor/local-notifications';
import toast from 'svelte-french-toast';

export async function _scheduleRecurringNotification(hour = 13, minute = 0) {
	await _doPermissionsCheck();
	try {
		await LocalNotifications.schedule({
			notifications: [
				{
					title: m.house_aloof_wallaby_agree(),
					body: m.inclusive_east_sawfish_trim(),
					id: currentDefaultNotificationID,
					// extra: {
					//     url: '/check-in',
					// },

					// actionTypeId: 'CHECK_IN',
					schedule: {
						repeats: true,
						on: {
							hour,
							minute,
						},
						// allowWhileIdle: true,
					},
					// group: 'check-in', // android only
					// channelId: 'reminders', // android only
					// TODO: Implement sound
				},
			],
		});
	} catch (e) {
		console.error('Error scheduling notification', e);
		toast.error(m.yummy_ornate_dolphin_pray());
	}

	queryClient.invalidateQueries({ queryKey: createLocalNotificationQueryKey('pending') });
}
