/* eslint-disable */
import { languageTag } from "../../runtime.js"
import * as en from "../en/house_aloof_wallaby_agree.js"
import * as nl from "../nl/house_aloof_wallaby_agree.js"
import * as fil from "../fil/house_aloof_wallaby_agree.js"


/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "en" | "nl" | "fil" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const house_aloof_wallaby_agree = (params = {}, options = {}) => {
	return {
		en: en.house_aloof_wallaby_agree,
		fil: fil.house_aloof_wallaby_agree,
		nl: nl.house_aloof_wallaby_agree
	}[options.languageTag ?? languageTag()]()
}

