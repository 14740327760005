import {
	allAllowedNotificationIDs,
	initialReleaseNotificationID,
} from '$lib/localNotifications/consts';
import { _scheduleRecurringNotification } from '$lib/localNotifications/scheduleRecurringNotification';
import { createLocalNotificationQueryKey } from '$lib/localNotifications/tanstack/createLocalNotificationQueryKey';
import queryClient from '$lib/utilities/tanstack/queryClient';
import { LocalNotifications } from '@capacitor/local-notifications';

// Migrate old broken notifications if they exist
export async function _migrateNotifications() {
	const pendingNotifications = await LocalNotifications.getPending();
	const mappedIDs = pendingNotifications.notifications.map((notification) => notification.id);
	const filteredNotificationIDs = mappedIDs.filter(
		(id) => !allAllowedNotificationIDs.includes(id),
	);

	if (filteredNotificationIDs.length) {
		const mappedCancelIDs = filteredNotificationIDs.map((id) => ({ id }));

		await LocalNotifications.cancel({
			notifications: mappedCancelIDs,
		});
		queryClient.invalidateQueries({ queryKey: createLocalNotificationQueryKey('pending') });
	}

	if (filteredNotificationIDs.includes(initialReleaseNotificationID)) {
		_scheduleRecurringNotification();
	}
}
